import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { useNavigate } from "react-router-dom";

const SignPreview = ({ pdf }) => {
    const WebDetails = useSelector((state) => state.logo);
    const Navigate = useNavigate();
    const [pdfUrl, setpdfUrl] = useState(`${methodModel.getPrams('pdf') || pdf}#toolbar=0&navpanes=0&scrollbar=0`)
    useEffect(() => {
        setpdfUrl(`${pdf}#toolbar=0&navpanes=0&scrollbar=0`)
    }, [pdf])
    const [zoom, setZoom] = useState(1);

    const handlePrint = () => {
        const pdfIframe = document.getElementById("pdf-viewer").contentWindow;
        pdfIframe.print();
    };

    return (
        <div className="py-2 mx-auto h-screen">
            <div className="flex flex-col items-center w-full justify-between relative">
                {/* Header */}
                <div className="px-4 py-2 header_top fixed top-0 left-0 w-full bg-blue-600 z-10">
                    <div className="flex justify-end">
                        <button className="bg-white px-4 text-sm font-normal text-blue-600 h-10 flex items-center justify-center gap-2 rounded-lg shadow-md hover:bg-blue-600 hover:text-white border border-blue-600 transition-all focus:ring-2 ring-blue-300"
                            onClick={() => Navigate('/login')}
                        >
                            Close
                        </button>
                    </div>
                </div>

                {/* Main Content */}
                <div className="innerdat flex-1 h-[calc(100vh-100px)] flex-grow overflow-auto mt-[50px] mb-[50px] bg-gray-100 w-full">
                    {/* PDF Viewer with Zoom Feature */}
                    <div className="w-full p-4 min-h-full overflow-auto flex justify-center">
                        <div className="max-w-4xl border border-gray-100 px-6 relative transition-transform duration-300 ease-in-out"
                            style={{ transform: `scale(${zoom})`, transformOrigin: "center" }}>
                            <iframe
                                id="pdf-viewer"
                                src={pdfUrl}
                                className="w-full h-[80vh] border-none"
                                title="PDF Viewer"
                                style={{ backgroundColor: "transparent" }} // Remove background color
                            ></iframe>
                        </div>
                    </div>

                    {/* Sidebar */}
                    <div className="shrink-0 fixed right-0 py-4 top-[50px] bottom-[50px] h-[calc(100vh-100px)] px-4 bg-white border-l border-gray-200 flex flex-col justify-between">
                        {/* Toolbar */}
                        <div className="flex flex-col gap-2">
                            <button onClick={() => setZoom((prev) => Math.min(prev + 0.2, 3))} className="p-2 rounded hover:bg-gray-200" title="Zoom In">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12h6m-3-3v6M4 12h6m-3-3v6" />
                                </svg>
                            </button>
                            <button onClick={() => setZoom((prev) => Math.max(prev - 0.2, 0.5))} className="p-2 rounded hover:bg-gray-200" title="Zoom Out">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12h6M4 12h6" />
                                </svg>
                            </button>
                            {/* <button onClick={handlePrint} className="p-2 rounded hover:bg-gray-200" title="Print PDF">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 9V2h12v7m-3 0v6h-6V9m-3 3h12M6 18h12v4H6v-4z" />
                                </svg>
                            </button> */}
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <div className="mt-4 footer-bottom fixed bottom-0 left-0 w-full z-10">
                    <div className="flex px-6 items-center justify-between bg-white shadow border-t border-gray py-4 mt-3 flex-wrap">
                        <div className="flex gap-2 items-center">
                            <p className="text-gray-800 font-normal text-sm">Powered by</p>
                            <img src={methodModel.userImg(WebDetails?.logo)} className="h-4 object-contain" alt="Logo" />
                        </div>
                        <div>
                            <p className="text-gray-800 font-normal text-sm">
                                Copyright © <span id="year">{new Date().getFullYear()}</span> Dashboards, Inc. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignPreview;
